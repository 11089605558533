import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { AuthProvider } from './contexts/AuthContext';
import AuthErrorHandler from './components/Common/AuthErrorHandler';

// Layout
import MainLayout from './components/Layout/MainLayout';

// Auth Pages
import LoginPage from './components/Auth/LoginPage';
import RegisterPage from './components/Auth/RegisterPage';
import OAuthCallback from './components/Auth/OAuthCallback';

// Public Pages
import HomePage from './components/Home/HomePage';
import NotFoundPage from './components/Common/NotFoundPage';

// Session Management
import SessionTimeout from './components/Auth/SessionTimeout';

// Protected Pages
import ProtectedRoute from './components/Common/ProtectedRoute';
import ApplicationList from './components/Websites/WebsiteList';
import WebsiteDetail from './components/Websites/WebsiteDetail';
import ApplicationsSetup from './components/Websites/WebsiteSetup';
import WebsiteEdit from './components/Websites/WebsiteEdit';
import InstallationGuides from './components/Guides/InstallationGuides';

// Analytics Dashboard - NEW IMPORTS
import DashboardPage from './components/Dashboard/DashboardPage';

// AI Components
import AIInstallationHelper from './components/AI/AIInstallationHelper';
import InstallationGuideAI from './components/AI/InstallationGuideAI';

// Support Components
import SupportForm from './components/Support/SupportForm';
import SupportTicketList from './components/Support/SupportTicketList';
import SupportPage from './components/Support/SupportPage';
import SupportTicketDetail from './components/Support/SupportTicketDetail';
import TestSupportMessages from './components/Support/TestSupportMessages';


// Create theme with blue primary and green secondary for color variation
const theme = createTheme({
  palette: {
    primary: {
      main: '#2563eb', // Blue from Tailwind blue-600
      light: '#3b82f6',
      dark: '#1d4ed8',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#10b981', // Green from Tailwind emerald-500
      light: '#34d399',
      dark: '#059669',
      contrastText: '#ffffff',
    },
    accent: {
      main: '#f59e0b', // Amber from Tailwind amber-500
      light: '#fbbf24',
      dark: '#d97706',
    },
    background: {
      default: '#f8fafc', // Light gray background similar to RYZE.ai
      paper: '#ffffff',
    },
    text: {
      primary: '#0f172a', // Tailwind slate-900
      secondary: '#64748b', // Tailwind slate-500
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 700,
      fontSize: '2.5rem',
    },
    h2: {
      fontWeight: 600,
      fontSize: '2rem',
    },
    h3: {
      fontWeight: 600,
      fontSize: '1.5rem',
    },
    button: {
      textTransform: 'none', // Prevents uppercase transformation of button text
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: '0.5rem 1.5rem',
        },
        containedPrimary: {
          boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
        },
        containedSecondary: {
          boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          boxShadow: '0 4px 6px rgba(0,0,0,0.05)',
          border: '1px solid rgba(0,0,0,0.03)',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 12,
        },
      },
    },
  },
});

function App() {
  const [globalNotifications, setGlobalNotifications] = useState([]);

  useEffect(() => {
    // Listen for global notifications
    const handleNewMessage = (event) => {
      const { ticketId, message } = event.detail;

      // Add to global notifications
      setGlobalNotifications(prev => [...prev, {
        id: Date.now(), // Unique ID for this notification
        ticketId,
        message,
        timestamp: new Date()
      }]);

      // Play notification sound
      const audio = new Audio('/notification-sound.mp3');
      audio.play().catch(e => console.log('Failed to play notification sound', e));

      // Change favicon if tab is not active
      if (document.hidden) {
        // Set notification favicon
        const originalFavicon = document.querySelector('link[rel="icon"]')?.href;
        const link = document.querySelector('link[rel="icon"]') || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'icon';
        link.href = '/notification-favicon.ico'; // Create this icon
        document.head.appendChild(link);

        // Restore original favicon when they return
        const handleVisibilityChange = () => {
          if (!document.hidden && originalFavicon) {
            link.href = originalFavicon;
            document.removeEventListener('visibilitychange', handleVisibilityChange);
          }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
      }
    };

    window.addEventListener('new-external-message', handleNewMessage);

    return () => {
      window.removeEventListener('new-external-message', handleNewMessage);
    };
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>

        <Router>

          <AuthErrorHandler />
          {/* Session timeout component will show up when timeout is imminent */}
          <SessionTimeout />

          <MainLayout>
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={<HomePage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/oauth-callback" element={<OAuthCallback />} />

              {/* OAuth Routes - will redirect to OAuthCallback */}
              <Route path="/api/auth/github/callback" element={<OAuthCallback provider="github" />} />
              <Route path="/api/auth/google/callback" element={<OAuthCallback provider="google" />} />

              <Route path="/support/test/:ticketId" element={<TestSupportMessages />} />

              {/* Protected Routes */}
              <Route element={<ProtectedRoute />}>
                {/* User Profile */}
                <Route path="/auth/me" element={<ApplicationList />} />

                {/* Website management routes */}
                <Route path="/websites" element={<ApplicationList />} />
                <Route path="/websites/:id" element={<WebsiteDetail />} />
                <Route path="/applications/:id/setup" element={<ApplicationsSetup />} />
                <Route path="/websites/:id/edit" element={<WebsiteEdit />} />
                <Route path="/websites/:id/settings" element={<WebsiteDetail />} />

                {/* Analytics Dashboard Routes */}
                <Route path="/websites/:websiteId/dashboard" element={<DashboardPage />} />
                <Route path="/dashboard/:websiteId" element={<DashboardPage />} />

                {/* Installation guides */}
                <Route path="/installation-guides" element={<InstallationGuides />} />
                <Route path="/websites/:id/installation-guides" element={<InstallationGuides />} />

                {/* AI Assistant */}
                <Route path="/websites/:id/installation-help" element={<AIInstallationHelper />} />
                <Route path="/websites/:id/ai-guide" element={<InstallationGuideAI />} />
                <Route path="/ai-helper" element={<AIInstallationHelper />} />

                {/* Support routes */}
                <Route path="/support" element={<SupportPage />} />
                <Route path="/support/:ticketId" element={<SupportTicketDetail />} />
                <Route path="/support/new" element={<SupportForm />} />
                <Route path="/support-tickets" element={<SupportTicketList />} />
                <Route path="/support/test/:ticketId" element={<TestSupportMessages />} />
              </Route>

              {/* 404 Page */}
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </MainLayout>
        </Router>

      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;