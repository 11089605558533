import React, { useState, useEffect } from 'react';
import styles from './Dashboard.module.css';
import VisitorStats from './VisitorStats';
import PageviewStats from './PageviewStats';
import SessionStats from './SessionStats';
import BounceRateWidget from './BounceRateWidget';
import SessionDurationWidget from './SessionDurationWidget';
import TopPagesWidget from './TopPagesWidget';
import ReferrersWidget from './ReferrersWidget';
import DevicesWidget from './DevicesWidget';
import InsightsWidget from './InsightsWidget';
import { fetchDashboardSummary } from '../../services/analyticsApi';
import LoadingSpinner from '../Common/LoadingSpinner';
import ErrorMessage from '../Common/ErrorMessage';
import TimeRangeSelector from './TimeRangeSelector';

const Dashboard = ({ websiteId }) => {
  const [timeRange, setTimeRange] = useState('7d');
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadDashboardData = async () => {
      try {
        setLoading(true);
        const data = await fetchDashboardSummary(websiteId, timeRange);
        setDashboardData(data);
        setError(null);
      } catch (err) {
        console.error('Error fetching dashboard data:', err);
        setError('Failed to load dashboard data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    loadDashboardData();
  }, [websiteId, timeRange]);

  const handleTimeRangeChange = (newRange) => {
    setTimeRange(newRange);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <ErrorMessage message={error} />;
  }

  if (!dashboardData) {
    return <div className={styles.noData}>No data available</div>;
  }

  return (
    <div className={styles.dashboardContainer}>
      <div className={styles.dashboardHeader}>
        <h1 className={styles.dashboardTitle}>
          {dashboardData.websiteName} Dashboard
        </h1>
        <TimeRangeSelector 
          currentRange={timeRange} 
          onRangeChange={handleTimeRangeChange} 
        />
      </div>

      <div className={styles.statsRow}>
        <VisitorStats data={dashboardData.visitors} />
        <SessionStats data={dashboardData.sessions} />
        <PageviewStats data={dashboardData.pageViews} />
      </div>

      <div className={styles.metricsRow}>
        <BounceRateWidget data={dashboardData.bounceRate} />
        <SessionDurationWidget data={dashboardData.avgSessionDuration} />
      </div>

      <div className={styles.detailsRow}>
        <div className={styles.leftColumn}>
          <TopPagesWidget data={dashboardData.topPages} />
          <ReferrersWidget data={dashboardData.topReferrers} />
        </div>
        <div className={styles.rightColumn}>
          <DevicesWidget data={dashboardData.deviceBreakdown} />
          <InsightsWidget insights={dashboardData.insights} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
