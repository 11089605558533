import React from 'react';
import styles from './TimeRangeSelector.module.css';

const TimeRangeSelector = ({ currentRange, onRangeChange }) => {
  const timeRanges = [
    { value: '24h', label: 'Last 24 hours' },
    { value: '7d', label: 'Last 7 days' },
    { value: '30d', label: 'Last 30 days' },
    { value: '90d', label: 'Last 90 days' }
  ];

  return (
    <div className={styles.container}>
      <select
        className={styles.select}
        value={currentRange}
        onChange={(e) => onRangeChange(e.target.value)}
      >
        {timeRanges.map((range) => (
          <option key={range.value} value={range.value}>
            {range.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default TimeRangeSelector;
