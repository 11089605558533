import React from 'react';
import styles from './TableWidget.module.css';

const ReferrersWidget = ({ data }) => {
  // Helper function to format referrer sources
  const formatReferrer = (source) => {
    if (source === 'direct') {
      return 'Direct / None';
    }
    
    try {
      const url = new URL(source);
      return url.hostname;
    } catch (e) {
      return source;
    }
  };

  return (
    <div className={styles.tableWidget}>
      <h3 className={styles.tableTitle}>Top Referrers</h3>
      
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Source</th>
              <th className={styles.numberColumn}>Visits</th>
              <th className={styles.numberColumn}>%</th>
            </tr>
          </thead>
          <tbody>
            {data.map((referrer, index) => (
              <tr key={index}>
                <td 
                  className={styles.sourceCell} 
                  title={referrer.source}
                >
                  {formatReferrer(referrer.source)}
                </td>
                <td className={styles.numberColumn}>{referrer.visits.toLocaleString()}</td>
                <td className={styles.numberColumn}>{referrer.percentage.toFixed(1)}%</td>
              </tr>
            ))}
            {data.length === 0 && (
              <tr>
                <td colSpan="3" className={styles.noData}>No data available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ReferrersWidget;
