import React from 'react';
import styles from './TrendIndicator.module.css';

const TrendIndicator = ({ value }) => {
  // If trend is zero, we'll show a neutral indicator
  if (value === 0) {
    return (
      <div className={styles.neutral}>
        <span className={styles.neutralIcon}>―</span>
        <span className={styles.trendValue}>0%</span>
      </div>
    );
  }

  // Positive trend
  if (value > 0) {
    return (
      <div className={styles.positive}>
        <span className={styles.positiveIcon}>↑</span>
        <span className={styles.trendValue}>{Math.abs(value).toFixed(1)}%</span>
      </div>
    );
  }

  // Negative trend
  return (
    <div className={styles.negative}>
      <span className={styles.negativeIcon}>↓</span>
      <span className={styles.trendValue}>{Math.abs(value).toFixed(1)}%</span>
    </div>
  );
};

export default TrendIndicator;
