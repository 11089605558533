import React from 'react';
import styles from './DevicesWidget.module.css';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts';

const DevicesWidget = ({ data }) => {
  // Colors for different device types
  const COLORS = ['#4A6FFF', '#FF8A4A', '#4AC1FF', '#A155B9', '#16C79A'];
  
  // Capitalize device names
  const formattedData = data.map(item => ({
    name: item.name.charAt(0).toUpperCase() + item.name.slice(1),
    value: item.value
  }));

  return (
    <div className={styles.deviceWidget}>
      <h3 className={styles.deviceTitle}>Device Types</h3>
      
      <div className={styles.chartContainer}>
        {formattedData.length > 0 ? (
          <ResponsiveContainer width="100%" height={220}>
            <PieChart>
              <Pie
                data={formattedData}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                paddingAngle={2}
                dataKey="value"
                labelLine={false}
                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
              >
                {formattedData.map((entry, index) => (
                  <Cell 
                    key={`cell-${index}`} 
                    fill={COLORS[index % COLORS.length]} 
                  />
                ))}
              </Pie>
              <Tooltip 
                formatter={(value) => [`${value.toFixed(1)}%`, 'Percentage']}
              />
            </PieChart>
          </ResponsiveContainer>
        ) : (
          <div className={styles.noData}>No device data available</div>
        )}
      </div>
    </div>
  );
};

export default DevicesWidget;
