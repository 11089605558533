// Helper function to get auth token
const getAuthToken = () => {
  try {
    // Get token from localStorage directly
    return localStorage.getItem('auth_token');
  } catch (error) {
    console.error('Error getting auth token:', error);
    return null;
  }
};

const API_BASE_URL = 'https://analytics-hub.xyz/api/analytics';

// Helper function to make API requests
const apiRequest = async (endpoint, options = {}) => {
  const token = getAuthToken();

  const defaultOptions = {
    headers: {
      'Authorization': token ? `Bearer ${token}` : '',
      'Content-Type': 'application/json',
    },
  };

  const response = await fetch(
    endpoint,
    { ...defaultOptions, ...options }
  );

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`API request failed: ${response.status} ${errorText}`);
  }

  return response.json();
};

// Define analytics functions
const fetchVisitors = async (websiteId, timeRange = '7d') => {
  const endpoint = `${API_BASE_URL}/visitors/${websiteId}?time_range=${timeRange}`;
  return apiRequest(endpoint);
};

const fetchSessions = async (websiteId, timeRange = '7d') => {
  const endpoint = `${API_BASE_URL}/sessions/${websiteId}?time_range=${timeRange}`;
  return apiRequest(endpoint);
};

const fetchPageviews = async (websiteId, timeRange = '7d') => {
  const endpoint = `${API_BASE_URL}/pageviews/${websiteId}?time_range=${timeRange}`;
  return apiRequest(endpoint);
};

const fetchBounceRate = async (websiteId, timeRange = '7d') => {
  const endpoint = `${API_BASE_URL}/bounce-rate/${websiteId}?time_range=${timeRange}`;
  return apiRequest(endpoint);
};

const fetchSessionDuration = async (websiteId, timeRange = '7d') => {
  const endpoint = `${API_BASE_URL}/session-duration/${websiteId}?time_range=${timeRange}`;
  return apiRequest(endpoint);
};

const fetchTopPages = async (websiteId, timeRange = '7d', limit = 5) => {
  const endpoint = `${API_BASE_URL}/top-pages/${websiteId}?time_range=${timeRange}&limit=${limit}`;
  return apiRequest(endpoint);
};

const fetchReferrers = async (websiteId, timeRange = '7d', limit = 5) => {
  const endpoint = `${API_BASE_URL}/referrers/${websiteId}?time_range=${timeRange}&limit=${limit}`;
  return apiRequest(endpoint);
};

const fetchDevices = async (websiteId, timeRange = '7d') => {
  const endpoint = `${API_BASE_URL}/devices/${websiteId}?time_range=${timeRange}`;
  return apiRequest(endpoint);
};

const fetchInsights = async (websiteId, timeRange = '7d') => {
  const endpoint = `${API_BASE_URL}/insights/${websiteId}?time_range=${timeRange}`;
  return apiRequest(endpoint);
};

const fetchDashboardSummary = async (websiteId, timeRange = '7d') => {
  const endpoint = `${API_BASE_URL}/summary/${websiteId}?time_range=${timeRange}`;
  return apiRequest(endpoint);
};

// Export named functions for direct imports
export {
  fetchVisitors,
  fetchSessions,
  fetchPageviews,
  fetchBounceRate,
  fetchSessionDuration,
  fetchTopPages,
  fetchReferrers,
  fetchDevices,
  fetchInsights,
  fetchDashboardSummary
};

// Export default object with all functions
export default {
  fetchVisitors,
  fetchSessions,
  fetchPageviews,
  fetchBounceRate,
  fetchSessionDuration,
  fetchTopPages,
  fetchReferrers,
  fetchDevices,
  fetchInsights,
  fetchDashboardSummary
};