import React from 'react';
import styles from './TableWidget.module.css';

const TopPagesWidget = ({ data }) => {
  return (
    <div className={styles.tableWidget}>
      <h3 className={styles.tableTitle}>Top Pages</h3>
      
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Page</th>
              <th className={styles.numberColumn}>Views</th>
              <th className={styles.numberColumn}>%</th>
            </tr>
          </thead>
          <tbody>
            {data.map((page, index) => (
              <tr key={index}>
                <td className={styles.pagePathCell} title={page.path}>
                  {page.path}
                </td>
                <td className={styles.numberColumn}>{page.views.toLocaleString()}</td>
                <td className={styles.numberColumn}>{page.percentage.toFixed(1)}%</td>
              </tr>
            ))}
            {data.length === 0 && (
              <tr>
                <td colSpan="3" className={styles.noData}>No data available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TopPagesWidget;
