import React from 'react';
import styles from './MetricWidget.module.css';
import TrendIndicator from './TrendIndicator';

const SessionDurationWidget = ({ data }) => {
  const { value, trend } = data;

  return (
    <div className={styles.metricWidget}>
      <div className={styles.metricHeader}>
        <h3 className={styles.metricTitle}>Avg. Session Duration</h3>
        <TrendIndicator value={trend} />
      </div>
      
      <div className={styles.metricValueContainer}>
        <div className={styles.metricValue}>{value}</div>
        <div className={styles.metricDescription}>
          Average time visitors spend on your site
        </div>
      </div>
    </div>
  );
};

export default SessionDurationWidget;
