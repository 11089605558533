// Helper function to get auth token directly from localStorage
const getAuthToken = () => {
  try {
    // Get token from localStorage directly
    return localStorage.getItem('auth_token');
  } catch (error) {
    console.error('Error getting auth token:', error);
    return null;
  }
};

const API_BASE_URL = 'https://analytics-hub.xyz/api/websites';

// Helper function to make API requests
const apiRequest = async (endpoint, options = {}) => {
  const token = getAuthToken();

  const defaultOptions = {
    headers: {
      'Authorization': token ? `Bearer ${token}` : '',
      'Content-Type': 'application/json',
    },
  };

  const response = await fetch(
    endpoint,
    { ...defaultOptions, ...options }
  );

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`API request failed: ${response.status} ${errorText}`);
  }

  return response.json();
};

// Fetch all websites for the current user
export const fetchWebsites = async () => {
  return apiRequest(`${API_BASE_URL}/`);
};

// Fetch details for a specific website
export const fetchWebsiteDetails = async (websiteId) => {
  return apiRequest(`${API_BASE_URL}/${websiteId}`);
};

// Create a new website
export const createWebsite = async (websiteData) => {
  return apiRequest(`${API_BASE_URL}/`, {
    method: 'POST',
    body: JSON.stringify(websiteData),
  });
};

// Update a website
export const updateWebsite = async (websiteId, websiteData) => {
  return apiRequest(`${API_BASE_URL}/${websiteId}`, {
    method: 'PUT',
    body: JSON.stringify(websiteData),
  });
};

// Delete a website
export const deleteWebsite = async (websiteId) => {
  return apiRequest(`${API_BASE_URL}/${websiteId}`, {
    method: 'DELETE',
  });
};

// Update website status
export const updateWebsiteStatus = async (websiteId, statusData) => {
  return apiRequest(`${API_BASE_URL}/${websiteId}/status`, {
    method: 'PATCH',
    body: JSON.stringify(statusData),
  });
};

// Update tracking options
export const updateTrackingOptions = async (websiteId, trackingData) => {
  return apiRequest(`${API_BASE_URL}/${websiteId}/tracking-options`, {
    method: 'PATCH',
    body: JSON.stringify(trackingData),
  });
};

export default {
  fetchWebsites,
  fetchWebsiteDetails,
  createWebsite,
  updateWebsite,
  deleteWebsite,
  updateWebsiteStatus,
  updateTrackingOptions
};