import React from 'react';
import styles from './StatsWidget.module.css';
import { LineChart, Line, XAxis, Tooltip, ResponsiveContainer } from 'recharts';
import TrendIndicator from './TrendIndicator';

const PageviewStats = ({ data }) => {
  const { total, trend, chartData } = data;

  return (
    <div className={styles.statsWidget}>
      <div className={styles.statsHeader}>
        <h3 className={styles.statsTitle}>Pageviews</h3>
        <TrendIndicator value={trend} />
      </div>
      
      <div className={styles.statsValue}>{total.toLocaleString()}</div>
      
      <div className={styles.chartContainer}>
        <ResponsiveContainer width="100%" height={80}>
          <LineChart data={chartData}>
            <XAxis 
              dataKey="date" 
              tick={false} 
              axisLine={false} 
            />
            <Tooltip 
              formatter={(value) => [value, 'Pageviews']}
              labelFormatter={(label) => `Date: ${label}`}
            />
            <Line 
              type="monotone" 
              dataKey="value" 
              stroke="#FF8A4A" 
              strokeWidth={2}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default PageviewStats;
