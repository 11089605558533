import React from 'react';
import styles from './InsightsWidget.module.css';

// Helper function to get icon based on insight type
const getInsightIcon = (type) => {
  switch (type) {
    case 'content':
      return '📄';
    case 'referral':
      return '🔗';
    case 'traffic':
      return '📈';
    case 'performance':
      return '⚡';
    case 'device':
      return '📱';
    case 'conversion':
      return '💰';
    default:
      return '💡';
  }
};

const InsightsWidget = ({ insights }) => {
  return (
    <div className={styles.insightsWidget}>
      <h3 className={styles.insightsTitle}>Insights</h3>
      
      <div className={styles.insightsList}>
        {insights && insights.length > 0 ? (
          insights.map((insight, index) => (
            <div key={index} className={styles.insightItem}>
              <div className={styles.insightIcon}>{getInsightIcon(insight.type)}</div>
              <div className={styles.insightContent}>
                <h4 className={styles.insightTitle}>{insight.title}</h4>
                <p className={styles.insightDescription}>{insight.description}</p>
              </div>
            </div>
          ))
        ) : (
          <div className={styles.noData}>No insights available</div>
        )}
      </div>
    </div>
  );
};

export default InsightsWidget;
