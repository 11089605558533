import React from 'react';
import styles from './MetricWidget.module.css';
import TrendIndicator from './TrendIndicator';

const BounceRateWidget = ({ data }) => {
  const { value, trend } = data;

  return (
    <div className={styles.metricWidget}>
      <div className={styles.metricHeader}>
        <h3 className={styles.metricTitle}>Bounce Rate</h3>
        <TrendIndicator value={trend} />
      </div>
      
      <div className={styles.metricValueContainer}>
        <div className={styles.metricValue}>{value.toFixed(1)}%</div>
        <div className={styles.metricDescription}>
          Percentage of visitors who leave after viewing only one page
        </div>
      </div>
    </div>
  );
};

export default BounceRateWidget;
