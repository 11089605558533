import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Dashboard from './Dashboard';
import { fetchWebsiteDetails } from '../../services/websiteService';
import LoadingSpinner from '../Common/LoadingSpinner';
import ErrorMessage from '../Common/ErrorMessage';
import styles from './DashboardPage.module.css';

const DashboardPage = () => {
  const { websiteId } = useParams();
  const [website, setWebsite] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadWebsiteData = async () => {
      try {
        setLoading(true);
        const websiteData = await fetchWebsiteDetails(websiteId);
        setWebsite(websiteData);
        setError(null);
      } catch (err) {
        console.error('Error fetching website data:', err);
        setError('Failed to load website data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    if (websiteId) {
      loadWebsiteData();
    }
  }, [websiteId]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <ErrorMessage message={error} />;
  }

  if (!website) {
    return <div className={styles.notFound}>Website not found</div>;
  }

  return (
    <div className={styles.dashboardPage}>
      <Dashboard websiteId={websiteId} />
    </div>
  );
};

export default DashboardPage;
